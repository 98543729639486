import React from "react";

import { AuthProvider } from "./Auth";
import { ProductProvider } from "./Products";

export const AppProvider = ({ children }) => {

    return (
        <AuthProvider>
            <ProductProvider>
                {children}
            </ProductProvider>                       
        </AuthProvider>
    );

}
import api from "../services/api";
import { createContext } from "react";

export const ProductContext = createContext({});

export const ProductProvider = ({ children }) => {


    const listCategoriesApi = async () => {
        const token = localStorage.getItem("token");
        const response = await api.get('list/categories', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })

        return response;
    }  

    const listProductsCategory = async (id) => {
        const token = localStorage.getItem("token");
        const response = await api.get(`list/products/category/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return response;
    }  

    return (
        <ProductContext.Provider value={{ listCategoriesApi,listProductsCategory }}>
            {children}
        </ProductContext.Provider>
    )
}
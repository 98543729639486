import {
    useState
} from "react";

import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

export default function BudgetForm(props) {
    const { register, handleSubmit, formState } = useForm();

    const produtos = [
        { id: 1, name: 'Produto A' },
        { id: 2, name: 'Produto B' },
        { id: 3, name: 'Produto C' },
    ];

    const categories = [
        { id: 1, title: 'Categoria 1' },
        { id: 2, title: 'Categoria 2' },
        { id: 3, title: 'Categoria 3' },
    ];

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <VStack
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            id={props?.id}
            spacing={8}
            align="stretch"
            py={12}
            width={'75vw'}
        >
            <FormControl isRequired>
                <FormLabel>Nome</FormLabel>
                <Input
                    type="text"
                    name="nome"
                    defaultValue=""
                    {...register("nome", { required: true })}
                />
            </FormControl>

            <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                    type="email"
                    name="email"
                    defaultValue=""
                    {...register("email", { required: true })}
                />
            </FormControl>

            <FormControl isRequired>
                <FormLabel>Telefone</FormLabel>
                <Input
                    type="tel"
                    name="telefone"
                    defaultValue=""
                    {...register("telefone", { required: true })}
                />
            </FormControl>

            <FormControl isRequired>
                <FormLabel>Produto</FormLabel>
                <Select
                    name="produto"
                    defaultValue=""
                    {...register("produto", { required: true })}
                >
                    <option value="">Produto</option>
                    {produtos?.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </Select>
            </FormControl>

            <FormControl isRequired>
                <FormLabel>Família do Produto</FormLabel>
                <Select
                    name="familia"
                    defaultValue=""
                    {...register("familia", { required: true })}
                >
                    <option value="">Família do Produto</option>
                    {categories?.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.title}
                        </option>
                    ))}
                </Select>
            </FormControl>

            <FormControl>
                <FormLabel>Dê mais detalhes sobre o seu orçamento</FormLabel>
                <Textarea
                    name="details"
                    defaultValue=""
                    {...register("details")}
                    rows={6}
                />
            </FormControl>

            <Button
                type="submit"
                colorScheme="red"
                isLoading={formState.isSubmitting}
                loadingText="Enviando"
            >
                Solicitar Orçamento
            </Button>
        </VStack>
    );
}

import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Flex, Image, Button, IconButton } from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'

import LogoAzul from '../images/logo_azul.png'

const Header = (props) => {

  //const history = useHistory()

  return (
    <Flex
      position="fixed"
      right={0}
      top={0}
      height="72px"
      width="calc(100% - 72px)"
      padding="15px 20px"
      background="#FFF"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid #C4C4C4"
      zIndex={997}
    >

      <Link to="/home">
      <IconButton
        aria-label="Voltar"
        icon={<ArrowBackIcon />}
        colorScheme="red"
        variant="ghost"
        fontSize="18px"
        borderRadius="4px"
        mr={2}
      />
      </Link>
      <Link to="/home">
        <Image src={LogoAzul} alt="" h="20px" />
      </Link>
    </Flex>
  )
}

export default Header;
import {
    Box,
    Container,
    Flex,
    Heading,
    Image,
    List,
    ListItem,
    Button,
    UnorderedList,
    SimpleGrid,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useState,  useEffect, useLiveQuery } from "react";
import Drawer from '../../components/Drawer';
import Header from '../../components/Header';
import BudgetForm from '../../components/BudgetForm';
import Success from "../../components/Success";
import Quote from "../../images/quote.svg"
import ReasonsIcon from "../../images/reasons-icon.svg"
import ThumbVideo from "../../images/poster_video.png";
import { Link, useParams } from 'react-router-dom';

const Product = () => {





    const { id } = useParams();
    
    const [produto, setProduto] = useState({
        "id": 1,
        "category": "GETS",
        "title": "Sistema de Dentes Nemesys®",
        "subtitle": "Desenvolvido para escavadeiras de 160 toneladas, 200 toneladas e 250 toneladas, o sistema Nemisys é uma classe à parte.",
        "description": "ESCO® oferece o comprovado sistema de dentes de mineração Nemisys como uma atualização direta e substituição para o sistema SV2® e sistemas de soldagem concorrentes para escavadeiras hidráulicas de placa. Operando em mais de 700 máquinas em todo o mundo, o sistema Nemisys demonstrou liderança de mercado em confiabilidade, segurança local e aprimoramento do desempenho da máquina.",
        "pricipal_image": "https://www.wikiaves.com.br/img/semfoto.png",
        "benefits_image": "https://www.wikiaves.com.br/img/semfoto.png",
        "benefits": [
            {
                "title": "Maior disponibilidade da máquina.",
                "text": "Maior vida útil da peça e retenção superior resultam em menos G.E.T. planejado e não planejado substituições."
            },
            {
                "title": "Projetado para durar mais.",
                "text": "Materiais premium e fabricação de precisão fornecem durabilidade e confiabilidade inigualáveis."
            },
            {
                "title": "Menos paradas do britador.",
                "text": "Um sistema de travamento mais confiável reduz bastante as chances de desligamento do britador devido à perda do dente."
            }
        ],
        "title_tree": "Proteção avançada do adaptador.",
        "image_tree": "https://www.wikiaves.com.br/img/semfoto.png",
        "full_video": "https://www.youtube.com/embed/dfTLaH5NsM0", // Precisa ser o link do video Embed
        "reasons_title": "Porquê escolher Sistema de Dentes Nemisys",
        "reasons": [
            "10% menos força ao penetrar e aumento de penetração.",
            "Sistema de travamento integrado",
            "Menor estresse e redução do peso.",
            "Desgaste uniforme dos protetores."
        ],
        "success_case_description": "Explore as histórias de sucesso de clientes abaixo e veja como o desempenho comprovado do sistema Nemisys está ajudando as minas em todo o mundo a reduzir os custos operacionais, maximizar a disponibilidade da máquina e melhorar a segurança.",
        "success_cases": [
            {
                "title": "MINA VÊ GRANDES GANHOS DA NEMISYS® ATUALIZAÇÃO DO SISTEMA DE DENTE.",
                "description": "Essa mina de minério de ferro aumentou drasticamente a disponibilidade de suas máquinas simplesmente atualizando o G.E.T. de sua escavadeira frontal do sistema de um concorrente para o sistema Nemisys da ESCO.",
                "results": [
                    "<p><strong>80%</strong> mais vida útil do dente;</p>",
                    "<p>Instalação de peças <strong>50%</strong> mais rápida e remoção;</p>",
                    "<p><strong>50%</strong> menos adaptador intermediário substituições;</p>",
                    "<p><strong>Extramamente satisfeito com segurança melhorada.</strong></p>"
                ]
            }
        ],
        "staffs": [
            {
                "image": "https://www.wikiaves.com.br/img/semfoto.png",
                "name": "Humberto Rocha",
                "occupation": "Engenheiro Chefe",
                "occupation_2": "Projeto de Escavação",
                "text": "O Sistema de Dentes Nemesys é uma classe a parte, com 10% menos força de penetração para ganho de eficiência e redução de desgaste ao equipamento. Ligas mais resistentes e duradouras."
            },
            {
                "image": "https://www.wikiaves.com.br/img/semfoto.png",
                "name": "Carlos Monteiro",
                "occupation": "Engenheiro",
                "occupation_2": "Projeto de Escavação",
                "text": "As pontas N70 mantém a agudeza à medida que desgastam, resultando na excelente penetração e melhor utilização da vida útil."
            }
        ]
    });
    const [productSelected, setProductSelected] = useState(null);

    function thisIsMyCopy(title, text) {
        return `<h5>${title}</h5><p>${text}</p>`;
    }

    const handleClick = () => {
        const budgetForm = document.getElementById('budget-form');
        budgetForm.scrollIntoView({ behavior: 'smooth' });
    };

    
    useEffect(() => {
      /*  const produto_selected = products?.find(item => item.id === parseInt(id));

        if (produto_selected) {
            const models = ['model_one', 'model_two', 'model_three', 'model_four'];

            models.forEach(model => {
                if (produto_selected[model].length > 0) {
                    produto_selected[model] = JSON.parse(produto_selected[model]);
                } else {
                    produto_selected[model] = null;
                }
            });

            setProduto(produto_selected);
            setProductSelected(produto_selected.model_one[0]);
        }*/
    }, [id]);

    return (
        <>
        
            <Flex>
                <Drawer />
                <Header />
                <Container maxW="container.lg" mt="12">

                    <Flex maxW="960px" mx="auto" mt="48px" justifyContent="center">
                        <Box w={{ base: "100%", md: "50%" }}>
                            <Box maxW="500px" mx="auto" px={{ base: "15px", md: "0" }}>
                                <Heading as="h1" fontSize="24px" fontWeight="700" mt="24px" mb="24px" fontFamily="Montserrat">
                                    {produto?.name}
                                </Heading>
                                <Text fontSize="16px" fontWeight="bold" color="#000">
                                    {produto?.subtitle}
                                </Text>
                                {produto?.description.includes("</p>") ? (
                                    <Box dangerouslySetInnerHTML={{ __html: produto?.description }} />
                                ) : (
                                    <Text mt="24px" fontSize="14px" color="#000" lineHeight="30px">
                                            {produto?.description}
                                    </Text>
                                )}
                            </Box>
                        </Box>
                        <Box w={{ base: "100%", md: "50%" }} >
                            <Image src={produto?.pricipal_image} alt="" objectFit="cover" objectPosition={{ base: "bottom", md: "unset" }} borderRadius={{ base: "0", md: "0px 0px 0px 25px" }} w={{ base: "100%", md: "540px" }} h={{ base: "300px", md: "540px" }} mx={{ base: "auto", md: "0" }} ml={{ base: "0", md: "auto" }} mt={{ base: "24px", md: "0" }} position={{ base: "unset", md: "absolute" }} top="72px" right="0" />
                        </Box>
                    </Flex>


                    {/** BENEFITS */}
                    <Box marginTop={'250px'} marginBottom={'80px'}>
                        {produto?.benefits_image && (
                            <Image src={produto?.benefits_image} alt=" " w="full" h={{ base: "300px", md: "500px" }} objectFit="contain" my="8" />
                        )}
                        <Heading fontSize={{ base: "xl", md: "2xl" }} color="blue.700" my="8" maxWidth={{ base: "100%", md: "400px" }} zIndex="2">
                         </Heading>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing="8" m={'80px 0'}>
                            {produto?.benefits?.map((item) => {
                                return (
                                    <Box className="item" dangerouslySetInnerHTML={{ __html: thisIsMyCopy(item.title, item.text) }}>
                                    </Box>
                                );
                            })}
                        </SimpleGrid>
                    </Box>

                    {/** TREE */}

                    {produto?.title_tree && (
                        <Box display="flex" flexDirection="column" mt={10}>
                            <Heading size="md">{produto?.title_tree}</Heading>
                            <Text dangerouslySetInnerHTML={{ __html: produto?.text_image_tree }} />
                            {produto?.image_tree && <Image src={produto?.image_tree} alt={produto?.title_tree} mt={-8} />}
                            <List ml={12} fontFamily="Montserrat" fontWeight="medium" color="#383838">
                                {produto?.tree_list?.map((item) => (
                                    <ListItem key={item}>{item}</ListItem>
                                ))}
                            </List>
                        </Box>
                    )}

                    {/**VIDEO  */}

                    {produto?.full_video && (
                        <Box display="flex" justifyContent="center" alignItems="center" m="80px 0">
                            <iframe
                                poster={produto?.thumbnail_video ? produto?.thumbnail_video : ThumbVideo} borderRadius="10px" w="100%"
                                title='naruto'
                                height={'500px'}
                                width={'900px'}
                                src={produto?.full_video}
                                allowFullScreen></iframe>
                        </Box>
                    )}


                    {/**REASONS */}


                    <Box position="relative" py="100px">
                        <Heading as="h2" fontSize="32px" color="#bc080d" mb="24px">
                            {produto?.reasons_title}
                        </Heading>
                        <UnorderedList display="flex" justifyContent="space-between" flexWrap="wrap" listStyleType="none" pl="0">
                            {produto?.reasons.map((item) => {
                                if (item.includes("</")) {
                                    return (
                                        <ListItem className="code__html" position="relative" w="calc(40% - 60px)" mb="24px" ml="60px" key={item}>
                                            <div dangerouslySetInnerHTML={{ __html: item }} />
                                        </ListItem>
                                    );
                                } else {
                                    return (
                                        <ListItem position="relative" w="calc(40% - 60px)" mb="24px" ml="60px" key={item}>
                                            <Box as="span" position="absolute" top="0" left="-56px" w="40px" h="40px" bg={`url(${ReasonsIcon}) center no-repeat`} backgroundSize="contain" />
                                            {item}
                                        </ListItem>
                                    );
                                }
                            })}
                        </UnorderedList>
                        <Box position="absolute" top="0" left="-50vw" w="140vw" h="100%" bg="rgba(196, 196, 196, 0.15)" zIndex="-2" />
                    </Box>

                    {/** SUCCESS */}


                    {produto?.success_case_description && (
                        <Flex color="#043455" py={8} m={'80px 0'}>
                            <Box w={{ base: "100%", md: "50%" }}>
                                <Heading fontSize={{ base: "lg", md: "2xl" }} mb={6}>
                                    Cases de Sucesso
                                </Heading>
                                <Text fontSize={{ base: "md", md: "lg" }} lineHeight={{ base: "base", md: "28px" }}>
                                    {produto?.success_case_description}
                                </Text>
                            </Box>
                            <Box w={{ base: "100%", md: "50%" }}>
                                <Box
                                    w={180}
                                    h={180}
                                    transform={{ base: "none", md: "rotate(10deg)" }}
                                    fontSize="22px"
                                    display="flex"
                                    margin="0 auto"
                                    justifyContent={{ base: "center", md: "flex-end" }}
                                >
                                    <Success />
                                </Box>
                            </Box>
                        </Flex>
                    )}

                    { /**  SUCCESS CASE */}

                    {produto?.success_cases.length > 0 && produto?.success_cases.map(item => {
                        return (
                            <Flex color="#043455" py={8}>
                                <Box w={{ base: "100%", md: "50%" }} pr={{ base: 0, md: 4 }}>
                                    <Heading fontSize={{ base: "lg", md: "xl" }} mb={4}>
                                        {item.title}
                                    </Heading>
                                    <Text fontSize={{ base: "md", md: "lg" }} lineHeight={{ base: "base", md: "28px" }} dangerouslySetInnerHTML={{ __html: item.description }} />
                                    {item.results.length > 0 && (
                                        <Text as="p" fontWeight="bold" mt={4}>
                                            Resultado
                                        </Text>
                                    )}
                                    <List ml={4} mt={2}>
                                        {item.results.map(result_item => {
                                            return (
                                                <ListItem dangerouslySetInnerHTML={{ __html: result_item }} fontSize={{ base: "md", md: "lg" }} />
                                            );
                                        })}
                                    </List>
                                </Box>
                                <Box w={{ base: "100%", md: "50%" }} pl={{ base: 0, md: 4 }}>
                                    <Box as="img" src={item.success_image} alt="" />
                                </Box>
                            </Flex>
                        );
                    })}

                    {/** STAFF */}

                    {produto?.staffs && (
                        <Box padding={{ base: "40px 15px 80px", md: "40px 0 80px" }}>
                            <Heading as="h2" fontSize={{ base: "22px", md: "28px" }} color="#043455" mb="24px">
                                Staff Técnico
                            </Heading>
                            <Flex flexWrap="wrap">
                                {produto?.staffs.map((item) => {
                                    return (
                                        <Box key={item.name} className="item" padding="15px" flex={{ base: "100%", md: "50%" }}>
                                            <Flex alignItems="center">
                                                <Image src={item.image} alt={item.name} w="80px" h="80px" objectFit="cover" borderRadius="10px" mr="16px" />
                                                <Box>
                                                    <Text fontSize={{ base: "16px", md: "18px" }} fontWeight="bold" color="#043455" mb="4px">
                                                        {item.name}
                                                    </Text>
                                                    <Text fontSize={{ base: "16px", md: "18px" }} color="#383838" mb="4px">
                                                        {item.occupation}
                                                    </Text>
                                                    <Text fontSize={{ base: "16px", md: "18px" }} color="#383838">
                                                        {item.occupation_2}
                                                    </Text>
                                                </Box>
                                            </Flex>
                                            <Flex alignItems="center" mt="24px">
                                                <Image src={Quote} alt="Quote" w="30px" h="30px" mr="8px" color="#bc080d" />
                                                <Text fontSize={{ base: "16px", md: "18px" }} lineHeight={{ base: "28px", md: "32px" }} color="#383838">
                                                    {item.text}
                                                </Text>
                                            </Flex>
                                        </Box>
                                    );
                                })}
                            </Flex>
                        </Box>
                    )}

                    {/** ANCHOR  */}

                    <Flex position="sticky" bottom="15px" right="15px" zIndex="99" ml="auto">
                       
                        <Button bg="#bc080d" color="#fff" borderRadius="4px" px="24px" py="16px" boxShadow="0px 10px 60px rgba(124, 140, 166, 0.25)" onClick={()=> handleClick()}>
                            Solicitar Orçamento
                        </Button>

                    </Flex>

                    <Box id="budget-form">
                        <BudgetForm />
                    </Box>
                       
                    

                </Container>
            </Flex >
        </>
    )


    
};

export default Product;
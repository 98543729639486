import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import Product from "./pages/Products/Product";
import RegisterBudget from "./pages/Budgets/RegisterBudget";
import Budgets from "./pages/Budgets/Budgets";
import ListUsers from "./pages/Users/Users";
import CreateUser from "./pages/Users/CreateUser";

function Router() {

    const Private = ({ children }) => {
        // const { user, authenticated } = useContext(AuthContext);
        const token = localStorage.getItem('token');
        if (token === null) {
            return <Navigate to="/" />
        }

        return children;
    }

    return (

        <Routes>
            <Route exact path='/' element={<Login />} />

            <Route path='/home' element={
                <Private>
                    <Home />
                </Private>
            } />

            <Route path='/produtos/:search' element={
                <Private>
                    <Products />
                </Private>
            } />

            <Route path='/produto/:id' element={
                <Private>
                    <Product />
                </Private>
            } />

            <Route path='/registrar/orcamento' element={
                <Private>
                    <RegisterBudget />
                </Private>
            } />

            <Route path='/orcamentos' element={
                <Private>
                    <Budgets/>
                </Private>
            } />

            <Route path='/usuarios' element={
                <Private>
                    <ListUsers />
                </Private>
            } />

            <Route path='registrar/usuario' element={
                <Private>
                    <CreateUser />
                </Private>
            } />


        </Routes>
    )
}

export default Router;
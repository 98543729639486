import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    IconButton,
    Paper,
    Table,
    TableCaption,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { DeleteIcon, EmailIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import Drawer from '../../components/Drawer';
import Header from '../../components/Header';
import { ToastContainer } from 'react-toastify';





export default function Budgets() {
    const [rows, setRows] = useState([]);


    useEffect(() => {
        // função para carregar os dados da tabela
        // e atualizar o estado "rows"
    }, []);

    function handleDelete(event, id) {
        event.preventDefault();
        // função para deletar um item pelo id
    }

    function handleSendUnique(event, id) {
        event.preventDefault();
        // função para enviar um orçamento pelo id
    }

    function handleSendOrcamentos() {
        // função para enviar todos os orçamentos
    }


    return (
        <>
                        <Drawer />
            <Header />
        <Flex>

            <Box w="100%">
                
                <Container 
                    minWidth={'75vw'}
                    marginTop={{ base: '72px', md: '120px' }}
                    ml={{ base: '100px', md: '150px' }}>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Heading size="xl">Orçamentos</Heading>
                        <Button
                            leftIcon={<EmailIcon />}
                            colorScheme="red"
                            size="md"
                            onClick={handleSendOrcamentos}
                        >
                            Enviar Todos
                        </Button>
                    </Flex>
                    <Table variant="striped" colorScheme="gray" mt={'45px'}>
                        <TableCaption>Lista de orçamentos</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th>Cliente</Th>
                                <Th>Produto</Th>
                                <Th>Categoria</Th>
                                <Th>Status</Th>
                                <Th></Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {rows.length > 0 &&
                                rows.map((row) => (
                                    <Tr key={row.id}>
                                        <Td>{row.id}</Td>
                                        <Td>{row.full_name}</Td>
                                        <Td>{row.product_name}</Td>
                                        <Td>{row.category_name}</Td>
                                        <Td>
                                            <Text color={row.status ? "green.500" : "red.500"}>
                                                {row.status ? "Enviado" : "Pendente"}
                                            </Text>
                                        </Td>
                                        <Td>
                                            {!row.status && (
                                                <IconButton
                                                    colorScheme="red"
                                                    aria-label="Delete"
                                                    icon={<DeleteIcon />}
                                                    onClick={(e) => handleDelete(e, row.id)}
                                                />
                                            )}
                                        </Td>
                                        <Td>
                                            {!row.status && (
                                                <IconButton
                                                    colorScheme="green"
                                                    aria-label="Send"
                                                    icon={<EmailIcon />}
                                                    onClick={(e) => handleSendUnique(e, row.id)}
                                                />
                                            )}
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        pauseOnHover
                    />
                </Container>
            </Box>
        </Flex>
        </>
    );
}

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Icon,
    Button,
    Image,
    Center,
    Box,
    Text

} from '@chakra-ui/react';
import Logo from "../../images/logo_esco.png";
import { AuthContext } from "../../contexts/Auth";
const Login = () => {

    const { login, loading } = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        login(email, password);
    }
    return (
        <>
            <Stack maxH={'100vh'} direction={{ base: 'column', md: 'row' }} backgroundColor={'#DB1015'}>
                <Flex minH={'100vh'} flex={1} alt={'Login Image'}
                    objectFit={'cover'} backgroundRepeat={'no-repeat'} justifyContent={'end'} alignItems={'center'}>
                    <Box marginRight={12}>
                        <Box>
                            <Image src={Logo} maxWidth={'100%'}></Image>
                        </Box>
                    </Box>


                </Flex>
                <Flex p={8} flex={1} align={'center'} justify={'start'}>
                    <Stack spacing={2} w={'full'} maxW={'md'}>


                        <Box backgroundColor={'white'} padding={12} width={'550px'}>
                            <Text fontSize={22} fontWeight={800} paddingTop={2} paddingBottom={2}>Faça seu login.</Text>

                            <FormControl id="email" marginTop={5} marginBottom={4}>
                                <Input fontSize={'14px'} height={'44px'} backgroundColor={'rgba(176,176,176,0.1)'} placeholder={'Coloque seu email'} type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </FormControl>

                            <FormControl id="password">
                                <Input fontSize={'14px'} height={'44px'} backgroundColor={'rgba(176,176,176,0.1)'} placeholder={'Coloque sua senha'} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </FormControl>

                            <Stack spacing={3}>

                                <Button height={'50px'} marginTop={5} borderRadius={5} bgColor={'#DB1015'} type="submit" onClick={(e) => handleSubmit(e)} colorScheme={'red'} variant={'solid'}>
                                    Sign in
                                </Button>

                                <Stack align={'end'} color={'#04D194'} textDecoration={'underline'} fontSize={'14px'}>
                                    <Link >Esqueci a senha</Link>
                                </Stack>
                            </Stack>
                        </Box>

                    </Stack>
                </Flex>

            </Stack >
        </>
    );
}

export default Login;
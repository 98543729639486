import {
  useEffect,
  useState,
  useContext
} from "react";
import LogoBranco from "../images/logo_branco.svg";
import {
  Box,
  Image,
  Flex,
  useDisclosure,
  IconButton, Icon, Menu, MenuButton, MenuList, MenuItem,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import { Link, Navigate } from "react-router-dom";
import { HiOutlineHome } from "react-icons/hi";
import { MdAccountBalanceWallet, MdOutlineAccountCircle, MdLensBlur } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import ExitIcon from "../images/exit.svg";
import { ProductContext } from '../contexts/Products';


const Drawer = ({ perfilFoto, onClick }) => {

  const { listCategoriesApi } = useContext(ProductContext);


  const [categories, setCategories] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState('/dashboard');
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState();
  const imageSize = open ? "82px" : "52px";
  const imageWidth = open ? "65px" : "40px";
  const imageHeight = open ? "65px" : "40px";
  const textRight = open ? "0" : "200px";

  const handleLogout = () => {
    logout();
    onClose();
  }

  const handleTabClick = (path) => {
    setActiveTab(path);
    onClose();
  }

  async function listCategories() {
    try {
      const categoriasApi = await listCategoriesApi();
      setCategories(categoriasApi.data[0]);
      console.log(categoriasApi.data[0]);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {

      setUser(JSON.parse(user))

    }

      listCategories();

  }, []);

  function goToCategory(categoryID, title) {
    localStorage.setItem('categoryID', categoryID);
    
  }// Salva o categoryID no localStorage

  const logout = () => {
    // código para realizar logout
  };
  return (
    <>
      <Box sx={
        {
          pos: "fixed",
          left: "0",
          top: "0",
          minHeight: "100vh",
          w: open ? "200px" : "72px",
          bg: "#FFF",
          boxShadow: "-60px 0px 40px 40px #333",
          overflowY: "auto",
          pb: "72px",
          zIndex: "998",
        }
      }>
        <Box position="relative"
          height="72px"
          bg="#BC080D"
          overflow="hidden"
          display="flex"
          width={
            open ? "200px" : "72px"
          }
          cursor="pointer"
          borderRight="0.5px solid #fff"
          onClick={
            () => setOpen(!open)
          } >
          <Box position="absolute"
            zIndex="2"
            left="0"
            top="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="72px"
            width="72px"
            bg="#BC080D" >
            < Box as="span"
              position="relative"
              width="38px"
              height="4px"
              bg="#fff"
              borderRadius="16px" >
              <Box content=""
                width="38px"
                height="4px"
                bg="#fff"
                borderRadius="16px"
                position="absolute"
                top="-12px"
                left="0" />
              <Box content=""
                width="38px"
                height="4px"
                bg="#fff"
                borderRadius="16px"
                position="absolute"
                bottom="-12px"
                left="0" />
            </Box>
          </Box >
          <Box position="relative"
            height="72px"
            bg="#BC080D"
            overflow="hidden"
            display="flex"
            width={
              open ? "200px" : "72px"
            }
            cursor="pointer"
            borderRight="0.5px solid #fff">
            <Box position="absolute"
              zIndex="2"
              left="0"
              top="0"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="72px"
              width="72px"
              bg="#BC080D" >
              < Box as="span"
                position="relative"
                width="38px"
                height="4px"
                bg="#fff"
                borderRadius="16px" >
                <Box content=""
                  width="38px"
                  height="4px"
                  bg="#fff"
                  borderRadius="16px"
                  position="absolute"
                  top="-12px"
                  left="0" />
                <Box content=""
                  width="38px"
                  height="4px"
                  bg="#fff"
                  borderRadius="16px"
                  position="absolute"
                  bottom="-12px"
                  left="0" />
              </Box>
            </Box >

            <Box position="relative"
              left={
                open ? "72px" : "-200px"
              }
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="calc(200px - 72px)" >
              <Image src={
                LogoBranco
              }
              />
            </Box >
          </Box>
        </Box >


        <Box width="100%"
          position="relative"
          _after={
            {}
          } >
          <Flex className={
            open ? "opened" : ""
          }
            position="relative" >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              border="0.5px solid #C5CCE9"
              width={
                imageSize
              }
              height={
                imageSize
              }
              bg="#FFF"
              margin="16px 8px"
              onClick={
                () => setOpen(!open)
              }
              cursor="pointer" >
              <Image src={
                perfilFoto
              }
                width={
                  imageWidth
                }
                height={
                  imageHeight
                }
                borderRadius="50%"
                objectFit="cover" />

            </Box>
            <Box
              position="absolute"
              display="flex"
              flexDirection="column"
              margin="auto 0"
              right={
                textRight
              }
              top="0"
              bottom="0"
              height="max-content"
              width="100px"
            >
              <Text color="#043455"
                fontSize="14px"
                fontWeight="bold"
                margin="0 0 8px" >
                Bem - vindo de volta.
              </Text>
            </Box >
          </Flex>
        </Box >
        <Box bg="#FFF" paddingTop={2}>
          <Flex align="center" justifyContent={'start'} alignItems={'center'} px={[4, 4]}>
            {open ?

              <Link to={'/home'}>
              <Box
                minWidth={'170px'}
                padding={1}
                display={
                  'inline-flex'
                } justifyContent={'start'} alignItems={'center'}
                color={'#043455'}
                _hover={{
                  backgroundColor: "#BC080D",
                  color: 'white',
                  fontcolor: 'white'
                }}
                _focus={{
                  backgroundColor: "#BC080D",
                  color: 'white'
                }}
                borderRadius={5}
              >
                
                <Icon
                  as={HiOutlineHome}
                  fontSize={open ? "22px" : "20px"}
                  aria-label="Menu"
                />
                <Text margin={'4px 14px'} fontSize={'14px'} fontWeight={600} textAlign={'center'}>  Inicio</Text>
                
              </Box>
              </Link>
              :
              <Link to={'/home'}>
              <IconButton
                fontSize={open ? "22px" : "20px"}
                aria-label="Menu"
                icon={<Icon as={HiOutlineHome} />}
                variant="ghost"
                _hover={{
                  backgroundColor: "#BC080D",
                  color: 'white'
                }}
                _focus={{
                  backgroundColor: "#BC080D",
                  color: 'white'
                }}>
                </IconButton>
              </Link>
            }
          </Flex>

          {user?.role_id == 1 ? (
            <>
              <Flex align="center" justifyContent={'start'} alignItems={'center'} px={[4, 4]} marginTop={2}>
                {open ? (

                  <Accordion allowToggle borderColor={'transparent'}>
                    <AccordionItem isDisabled={!open}>
                      <h2>
                        <AccordionButton
                          minWidth={'170px'}
                          padding={1}
                          display={
                            'inline-flex'
                          } justifyContent={'start'} alignItems={'center'}
                          color={'#043455'}
                          _hover={{
                            backgroundColor: "#BC080D",
                            color: 'white',
                            fontcolor: 'white'
                          }}
                          _focus={{
                            backgroundColor: "#BC080D",
                            color: 'white'
                          }}
                          borderRadius={5}
                        >
                          <Icon
                            as={MdAccountBalanceWallet}
                            fontSize={open ? "22px" : "20px"}
                            aria-label="Menu"
                          />
                          <Text
                            margin={'4px 14px'} fontSize={'14px'} fontWeight={600} textAlign={'center'}
                          >
                            Orçamentos
                          </Text>
                          </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        <Box
                          maxWidth="170px"
                          fontSize={'13px'}
                          display="inline-flex"
                          justifyContent="start"
                          alignItems="start"
                          flexDirection="column" // adiciona esta propriedade
                          color="#043455"
                        >
                          <Link  fontWeight={600} textAlign={'center'} to={"/registrar/orcamento"} marginBottom={1}>
                            <Text p={'4px 0'} _hover={{ borderBottom: '1px solid #043455' }}>
                              Novo
                            </Text>
                          </Link>
                          <Link fontWeight={600} textAlign={'center'} to={"/orcamentos"}>
                            <Text p={'4px 0'} _hover={{ borderBottom: '1px solid #043455' }}>
                               Histórico
                           </Text>
                          </Link>
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                ) :
                  <IconButton
                    fontSize={open ? "22px" : "20px"}
                    aria-label="Menu"
                    icon={<Icon as={MdAccountBalanceWallet} />}
                    variant="ghost"
                    _hover={{
                      backgroundColor: "#BC080D",
                      color: 'white'
                    }}
                    _focus={{
                      backgroundColor: "#BC080D",
                      color: 'white'
                    }} />
                }

              </Flex>

              <Flex align="center" justifyContent={'start'} alignItems={'center'} px={[4, 4]} marginTop={2} >

              {open ? (
                <Accordion allowToggle borderColor={'transparent'}>
                  <AccordionItem isDisabled={!open}>
                    <h2>
                      <AccordionButton
                        minWidth={'165px'}
                        padding={1}
                        display={
                          'inline-flex'
                        } justifyContent={'start'} alignItems={'center'}
                        color={'#043455'}
                        _hover={{
                          backgroundColor: "#BC080D",
                          color: 'white',
                          fontcolor: 'white'
                        }}
                        _focus={{
                          backgroundColor: "#BC080D",
                          color: 'white'
                        }}
                        borderRadius={5}
                      >
                        <Icon
                          as={MdOutlineAccountCircle}
                          fontSize={open ? "20px" : "18px"}
                          aria-label="Menu"
                        />
                        <Text
                          margin={'4px 14px'} fontSize={'14px'} fontWeight={600} textAlign={'center'}
                        >
                          Usuários
                        </Text>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel>
                      <Box
                        maxWidth="170px"
                          fontSize={'12px'}
                        display="inline-flex"
                        justifyContent="start"
                        alignItems="start"
                        flexDirection="column" // adiciona esta propriedade
                        color="#043455"
                      >
                          <Link fontWeight={600} textAlign={'center'} to="/registrar/usuario">
                            <Text p={'4px 0'} _hover={{ borderBottom: '1px solid #043455' }}>
                              Novo
                            </Text>
                          
                        </Link>
                          <Link fontWeight={600} textAlign={'center'} to="/usuarios">
                            <Text p={'4px 0'} _hover={{ borderBottom: '1px solid #043455' }}>
                              Listar Usuários
                            </Text>                          
                        </Link>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ) :
                <IconButton
                  fontSize={open ? "18px" : "20px"}
                  aria-label="Menu"
                  icon={<Icon as={MdOutlineAccountCircle} />}
                  variant="ghost"
                  _hover={{
                    backgroundColor: "#BC080D",
                    color: 'white'
                  }}
                  _focus={{
                    backgroundColor: "#BC080D",
                    color: 'white'
                  }} />
              }
            </Flex>
            </>
          ) : ''

          }



         

          <Flex align="center" justifyContent={'start'} alignItems={'center'} px={[4, 4]} marginTop={2} >

            {open ? (
              <Accordion allowToggle borderColor={'transparent'}>
                <AccordionItem isDisabled={!open}>
                  <h2>
                    <AccordionButton
                      minWidth={'165px'}
                      padding={1}
                      display={
                        'inline-flex'
                      } justifyContent={'start'} alignItems={'center'}
                      color={'#043455'}
                      _hover={{
                        backgroundColor: "#BC080D",
                        color: 'white',
                        fontcolor: 'white'
                      }}
                      _focus={{
                        backgroundColor: "#BC080D",
                        color: 'white'
                      }}
                      borderRadius={5}
                    >
                      <Icon
                        as={MdLensBlur}
                        fontSize={open ? "20px" : "18px"}
                        aria-label="Menu"
                      />
                      <Text
                        margin={'4px 14px'} fontSize={'14px'} fontWeight={600} textAlign={'center'}
                      >
                        Produtos
                      </Text>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    
                    <Box
                      maxWidth="170px"
                      fontSize={'13px'}
                      
                      display="inline-flex"
                      justifyContent="start"
                      alignItems="start"
                      flexDirection="column" // adiciona esta propriedade
                      color="#043455"
                    >     
                      {categories?.map((item) => (  
                        <Link to={`/produtos/${item.title}`} key={item.id} fontWeight={600} textAlign={'center'} onClick={() => goToCategory(item.id, item.title)}>
                          <Text p={'4px 0'} _hover={{ borderBottom: '1px solid #043455'}}>
                            {item.title}
                          </Text>
                            
                          </Link>
                      ))}
                    </Box>
                   
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            ) :
              <IconButton
                fontSize={open ? "18px" : "20px"}
                aria-label="Menu"
                icon={<Icon as={MdLensBlur} />}
                variant="ghost"
                _hover={{
                  backgroundColor: "#BC080D",
                  color: 'white'
                }}
                _focus={{
                  backgroundColor: "#BC080D",
                  color: 'white'
                }} />
            }
          </Flex>



          <Flex align="center" justifyContent={'start'} alignItems={'center'} px={[4, 4]} marginTop={2} >
            {open ? (
              <Flex
                borderTop="1px solid #E5E5E5"
                left={
                  0
                }
                bottom={0}
                position="absolute"
                zIndex={10}
                cursor={'pointer'}
                onClick={onClick}
                minWidth={'200px'}
                padding={1}
                display={
                  'inline-flex'
                }
                justifyContent={'start'}
                alignItems={'center'}
                color={'#043455'}
                borderRadius={5}
              >
                <Flex alignItems="center" marginLeft={5}>
                  <Image
                    src={ExitIcon}
                    alt="Ícone de saída"
                    width={open ? "22px" : "20px"}
                    height={16}
                    marginLeft={2}
                  />
                  <Text marginLeft={2}>Sair</Text>
                </Flex>
              </Flex>
            ) : (
              <Flex
                width={'72px'}
                justifyContent={'center'}
                alignItems="center"
                borderTop="1px solid #E5E5E5"
                bottom={0}
                left={0}
                right={0}
                position="absolute" >
                <Box

                >
                  <Image


                    cursor={'pointer'}
                    src={ExitIcon}
                    alt="Ícone de saída"
                    width={open ? "22px" : "20px"}
                    height={16}
                    marginLeft={2}
                  />
                </Box>
              </Flex>
            )}
          </Flex>


        </Box >


      </Box >
    </>
  );
};

export default Drawer;
import { useState, useEffect, useLiveQuery } from "react";
import api from "../services/api";
import {
    VStack,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    InputGroup,
    InputRightElement
} from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function NewUserForm(props) {

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [values, setValues] = useState({
        nome: "",
        email: "",
        cpf: "",
        telefone: "",
        data_nascimento: "",
        senha: "",
        confirm_senha: "",
        type: ""
    });

    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const resetForm = () => {
        setValues({
            nome: "",
            email: "",
            cpf: "",
            telefone: "",
            data_nascimento: "",
            senha: "",
            confirm_senha: "",
            type: ""
        });
    };

    const handleValidate = () => {
        let temp = {};
        temp.nome = values.nome.length !== 0 ? "" : "Este campo é obrigatório!";
        temp.email =
            values.email.length !== 0 && /\S+@\S+\.\S+/.test(values.email)
                ? ""
                : "Email inválido!";
        temp.cpf = values.cpf.length !== 0 ? "" : "Este campo é obrigatório!";
        temp.type = values.type.length !== 0 ? "" : "Este campo é obrigatório!";
        temp.telefone =
            values.telefone.length !== 0 ? "" : "Este campo é obrigatório!";
        temp.data_nascimento =
            values.data_nascimento.length !== 0
                ? ""
                : "Este campo é obrigatório!";
        temp.senha = values.senha.length !== 0 ? "" : "Este campo é obrigatório!";
        temp.confirm_senha =
            values.confirm_senha.length !== 0 && values.confirm_senha === values.senha
                ? ""
                : "As senhas estão diferentes!";
        setErrors({
            ...temp
        });

        return Object.values(temp).every((x) => x === "");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (handleValidate()) {
            const data = {
                name: values.nome,
                email: values.email,
                profile_image: "https://www.wikiaves.com.br/img/semfoto.png",
                cpf: values.cpf,
                type: values.type,
                phone: values.telefone,
                birth_date: values.data_nascimento,
                password: values.senha
            };

            try {
                const response = await api.post("/users", data);
                resetForm();
                toast.info(`${values.nome}, cadastrado com sucesso!`, {
                    autoClose: 5000
                });
            } catch (error) {
                console.log(error);
                toast.info("Erro ao se conectar com o servidor!", {
                    autoClose: 5000
                });
            }
        }

    };



    return (
        <VStack
            as="form"
            onSubmit={handleSubmit}
            autoComplete="off"
            id={props?.id}
            spacing={8}
            align="stretch"
            py={12}
            width={'75vw'}
        >

            <FormControl isRequired>
                <FormLabel>Nome</FormLabel>
                <Input
                    type="text"
                    name="nome"
                    value={values.nome}
                    onChange={(e) => handleChange(e)}
                    error={errors.nome}
                    variant="outline"
                />
            </FormControl>
            <FormControl isRequired marginBottom={12}>
                <FormLabel>Email</FormLabel>
                <Input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    error={errors.email}
                    variant="outline"
                />
            </FormControl>
            <FormControl isRequired marginBottom={12}>
                <FormLabel>CPF</FormLabel>
                <InputGroup>
                    <Input
                        type="tel"
                        name="cpf"
                        value={values.cpf}
                        onChange={handleChange}

                        //isInvalid={touched.cpf && errors.cpf}
                        placeholder="999.999.999-99"
                        mask="999.999.999-99"
                        maskChar=""
                    />
                </InputGroup>
            </FormControl>

            <FormControl isRequired marginBottom={12}>
                <FormLabel>Telefone</FormLabel>
                <InputGroup>
                    <Input
                        type="tel"
                        name="telefone"
                        value={values.telefone}
                        onChange={handleChange}

                        // isInvalid={touched.telefone && errors.telefone}
                        placeholder="(99) 9 9999-9999"
                        mask="(99) 9 9999-9999"
                        maskChar=""
                    />
                </InputGroup>
            </FormControl>

            <FormControl isRequired marginBottom={12}>
                <FormLabel>Data de nascimento</FormLabel>
                <InputGroup>
                    <Input
                        type="text"
                        name="data_nascimento"
                        value={values.data_nascimento}
                        onChange={handleChange}

                        // isInvalid={touched.data_nascimento && errors.data_nascimento}
                        placeholder="dd/mm/aaaa"
                        mask="99/99/9999"
                        maskChar=""
                    />
                </InputGroup>
            </FormControl>

            <FormControl isRequired marginBottom={12}>
                <FormLabel>Senha</FormLabel>
                <InputGroup>
                    <Input
                        type={showPassword ? "text" : "password"}
                        name="senha"
                        value={values.senha}
                        onChange={handleChange}

                        //isInvalid={touched.senha && errors.senha}
                        placeholder="********"
                    />
                    <InputRightElement>
                        {showPassword ? (
                            <FaEyeSlash onClick={{ handleClickShowPassword }} />
                        ) : (
                            <FaEye onClick={{ handleClickShowPassword }} />
                        )}
                    </InputRightElement>
                </InputGroup>
            </FormControl>

            <FormControl isRequired marginBottom={12}>
                <FormLabel>Confirmar Senha</FormLabel>
                <InputGroup>
                    <Input
                        type={showPassword ? "text" : "password"}
                        name="confirm_senha"
                        value={values.confirm_senha}
                        onChange={handleChange}
                        //isInvalid={touched.confirm_senha && errors.confirm_senha}
                        placeholder="********"
                    />
                    <InputRightElement>
                        {showPassword ? (
                            <FaEyeSlash onClick={{ handleClickShowPassword }} />
                        ) : (
                            <FaEye onClick={{ handleClickShowPassword }} />
                        )}
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            <FormControl isRequired marginBottom={12}>
                <FormLabel>Tipo de Usuário</FormLabel>
                <Select
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    error={errors.type}
                    variant="outline"
                >
                    <option value="" disabled>
                        Tipo de Usuário
                    </option>
                    <option value="admin">Administrador</option>
                    <option value="consultor">Consultores</option>
                </Select>
            </FormControl>
            <Button
                type="submit"
                padding="16px 24px"
                background="#BC080D"
                color="#FFF"
                borderRadius="6px"
                border="none"
                margin="0 0 24px"
                fontWeight="600"
            >
                Salvar
            </Button>


        </VStack>
    )
}

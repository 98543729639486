import { useState } from "react";
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Table,
    TableCaption,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import Drawer from '../../components/Drawer';
import Header from '../../components/Header';
import { ToastContainer } from 'react-toastify';
const ListUsers = () => {
    const [rows, setRows] = useState([]);

    const handleActive = (e, id) => {
        // your logic for handling user status
    };


    return (
        
        <>
            <Drawer />
            <Header />
            <Flex>

                <Box w="100%">

                    <Container
                        minWidth={'75vw'}
                        marginTop={{ base: '72px', md: '120px' }}
                        ml={{ base: '100px', md: '150px' }}>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Heading as="h1" color="blue.700" fontSize={{ base: "20px", md: "24px" }}>
                                Usuários
                            </Heading>
                            <Button
                                leftIcon={<AddIcon />}
                                bg="red.500"
                                color="white"
                                _hover={{ bg: "red.600" }}
                                size="sm"

                                fontSize={{ base: "12px", md: "14px" }}
                                px={{ base: "10px", md: "16px" }}
                                py={{ base: "6px", md: "8px" }}
                                mt={{ base: "16px", md: '16px' }}
                            >
                                Novo Usuário
                            </Button>
                        </Flex>
                        <Table variant="striped" colorScheme="gray" mt={'45px'}>
                            <TableCaption>
                                Lista de usuários cadastrados
                            </TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>Nome</Th>
                                    <Th>Data de Nascimento</Th>
                                    <Th>Telefone</Th>
                                    <Th>Tipo de Usuário</Th>
                                    <Th>Status</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {rows.length > 0 &&
                                    rows.map((row) => (
                                        <Tr key={row.id}>
                                            <Td>{row.name}</Td>
                                            <Td>{row.birth}</Td>
                                            <Td>{row.phone}</Td>
                                            <Td>
                                                <Text textTransform="capitalize">{row.type}</Text>
                                            </Td>
                                            <Td>
                                                <Button
                                                    size="sm"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                    borderRadius="6px"
                                                    colorScheme={row.status === "ativo" ? "green" : "red"}
                                                    onClick={(e) => handleActive(e, row.id)}
                                                    _hover={{ bg: row.status === "ativo" ? "green.600" : "red.600" }}
                                                >
                                                    {row.status}
                                                </Button>
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        </Table>
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            pauseOnHover
                        />
                    </Container>
                </Box>
            </Flex>
        </>
    )
};

export default ListUsers;
